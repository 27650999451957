// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "header-module--ReactModal__Body--open--a0d93";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "header-module--bodyLarge--943da";
export var bodyMedium = "header-module--bodyMedium--a0b93";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "header-module--bodySmall--04b9c";
export var bodyXlarge = "header-module--bodyXlarge--a793e";
export var bodyXsmall = "header-module--bodyXsmall--177d8";
export var bodyXxlarge = "header-module--bodyXxlarge--4755c";
export var bodyXxsmall = "header-module--bodyXxsmall--1ca39";
export var buttons = "header-module--buttons--1ae47";
export var closeButton = "header-module--closeButton--8ab95";
export var closeButtonText = "header-module--closeButtonText--002d1";
export var container = "header-module--container--ffbe1";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var header = "header-module--header--3c482";
export var headerContent = "header-module--headerContent--b94c1";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var minimized = "header-module--minimized--71df3";
export var modal = "header-module--modal--a2d43";
export var modalButtons = "header-module--modalButtons--ff210";
export var nav = "header-module--nav--cf5ad";
export var overlay = "header-module--overlay--4be8f";
export var right = "header-module--right--1a6ad";
export var slidIntoViewModal = "header-module--slidIntoViewModal--a2c8a";
export var title = "header-module--title--2539b";
export var titleLarge = "header-module--titleLarge--c82a2";
export var titleMedium = "header-module--titleMedium--8444f";
export var titleMega = "header-module--titleMega--04be2";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "header-module--titleSmall--472f4";
export var titleXlarge = "header-module--titleXlarge--0c211";
export var titleXsmall = "header-module--titleXsmall--4e7c0";
export var titleXxlarge = "header-module--titleXxlarge--f123c";
export var titleXxsmall = "header-module--titleXxsmall--1c07a";
export var topRow = "header-module--topRow--fe65e";