import React from 'react';
import Link from '../../link/link';
import PropTypes from 'prop-types';
import {
  headerModalBigSubmenu,
  title as titleStyle,
  link as linkStyle,
} from './header-modal-big-submenu.module.scss';

const HeaderModalBigSubmenu = ({ data: { title, links, extraClasses } }) => {
  return (
    <div
      className={`${headerModalBigSubmenu} ${extraClasses}`}
      data-testid="header-modal-big-submenu"
    >
      <h3 className={titleStyle}>{title}</h3>
      <ul>
        {links.map((link) => (
          <li key={link.key}>
            <Link className={linkStyle} id="nav-audience-menu" to={link.url}>
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

HeaderModalBigSubmenu.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    extraClasses: PropTypes.string,
  }),
};

export default HeaderModalBigSubmenu;
