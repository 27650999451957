exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-penn-state-news-js": () => import("./../../../src/pages/penn-state-news.js" /* webpackChunkName: "component---src-pages-penn-state-news-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-aggregator-aggregator-js": () => import("./../../../src/templates/aggregator/aggregator.js" /* webpackChunkName: "component---src-templates-aggregator-aggregator-js" */),
  "component---src-templates-basic-story-basic-story-js": () => import("./../../../src/templates/basic-story/basic-story.js" /* webpackChunkName: "component---src-templates-basic-story-basic-story-js" */),
  "component---src-templates-event-event-js": () => import("./../../../src/templates/event/event.js" /* webpackChunkName: "component---src-templates-event-event-js" */),
  "component---src-templates-feature-story-feature-story-js": () => import("./../../../src/templates/feature-story/feature-story.js" /* webpackChunkName: "component---src-templates-feature-story-feature-story-js" */),
  "component---src-templates-landing-page-landing-page-js": () => import("./../../../src/templates/landing-page/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-landing-page-js" */)
}

