import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * Generates Schema.org data for inserting in the header of story pages
 * @param {Object} node - Data for schema
 * @param {Array} imageUrl - url for images
 * @returns {Object} - json to insert in header
 */
export function generateSchema(node, imageUrl) {
  const siteUrl = process.env.SITE_URL;
  const authors = node.relationships.field_authors
    ? node.relationships.field_authors.map(auth => {
      return {
        '@type': 'Person',
        'name': `${auth.field_first_name} ${auth.field_last_name}`,
        'url':''
      };
    })
    : {};

  let originalSummary = node.headlinesSummary;
  if (!node.headlinesSummary) {
    originalSummary = node.summary ? node.summary.join('') : '';
  }
  let strippedSummary = originalSummary
    .replace(/(<([^>]+)>)/gi, ' ')
    .replace(/(\r\n|\n|\r)/gm, ' ');

  const schemaJson = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': siteUrl + node.path,
    },
    headline: node.title,
    'description': strippedSummary,
    'image': imageUrl && imageUrl.image ? imageUrl.image : '',
    author: authors,

    publisher: {
      '@type': 'Organization',
      name: '​Penn State News',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.psu.edu/components/img/psu-mark-footer.png',
        width: 164,
        height: 52,
      },
    },
    datePublished: node.publishDate,
  };
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaJson)}
      </script>
    </Helmet>
  );
}
/**
 * Generates Schema.org data for inserting in the header of regular pages
 * @returns {Object} - json to insert in header
 */
export function generateDefaultSchema() {
  const schemaJson = {
    '@context': 'https://schema.org',
    '@type': 'CollegeOrUniversity',
    'name': 'The Pennsylvania State University',
    'alternateName': 'Penn State University',
    'url': 'https://www.psu.edu/',
    'logo': 'https://www.psu.edu/psu-edu-assets/images/shared/psu-mark.svg',
    'address': {
      '@type': 'PostalAddress',
      'addressLocality': 'University Park',
      'addressRegion': 'PA',
      'postalCode': '16802',
      'streetAddress': '201 Old Main',
      'description': 'Penn State - The Pennsylvania State University'
    },
    'email': 'admissions@psu.edu',
    'faxNumber': '814-863-7590',
    'telephone': '814-865-4700',
    'sameAs': [
      'https://www.facebook.com/pennstate',
      'https://twitter.com/penn_state',
      'https://www.instagram.com/pennstate/',
      'https://www.linkedin.com/school/penn-state-university/',
      'https://www.youtube.com/pennstate'
    ]
  };
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaJson)}
      </script>
    </Helmet>
  );
}

