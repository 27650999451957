import React from 'react';
import PropTypes from 'prop-types';
import MagnifyingGlass from './assets/magnifying-glass.inline.svg';
import {
  headerModalButtons,
  search,
  hamburger,
  hamburgerBox,
  hamburgerInner,
} from './header-modal-buttons.module.scss';

const HeaderModalButtons = ({ data: { clickFunction, extraClasses = '' } }) => {
  return (
    <div
      className={`${headerModalButtons} ${extraClasses}`}
      data-testid="header-modal-buttons"
    >
      <button onClick={clickFunction} className={search} aria-label="Search">
        <MagnifyingGlass id="nav-search-icon" />
      </button>
      <button
        aria-label="Full menu"
        onClick={clickFunction}
        className={hamburger}
        id="nav-hamburger-click"
      >
        <span className={hamburgerBox} id="nav-hamburger-click">
          <span className={hamburgerInner} id="nav-hamburger-click" />
        </span>
      </button>
    </div>
  );
};

HeaderModalButtons.propTypes = {
  data: PropTypes.shape({
    extraClasses: PropTypes.string,
    clickFunction: PropTypes.func.isRequired,
  }).isRequired,
};

export default HeaderModalButtons;
