/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/roboto-slab/400.css';
import '@fontsource/roboto-slab/700.css';
import '@fontsource/roboto-condensed/400.css';
import '@fontsource/roboto-condensed/700.css';

// Resets dataLayer variables to null on routeChange.
// Since these variables are only set on article/basic page views we need to
// set them to undefined so they don't carry through to additional page routes.
export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== 'undefined') {
    let dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      articleName: undefined,
      articleTopic: undefined,
      articleAudience: undefined,
      articleCampus: undefined,
      articleCollege: undefined,
      articleUnit: undefined,
      articlePublishDate: undefined,
      id: undefined,
      primaryTopic: undefined,
      nodeId: undefined,
      wordCount: undefined,
      articleTags: undefined
    });
  }
};
