// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "header-modal-small-submenu-module--ReactModal__Body--open--54ff5";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "header-modal-small-submenu-module--bodyLarge--2b25c";
export var bodyMedium = "header-modal-small-submenu-module--bodyMedium--a4f1a";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "header-modal-small-submenu-module--bodySmall--70679";
export var bodyXlarge = "header-modal-small-submenu-module--bodyXlarge--8da4f";
export var bodyXsmall = "header-modal-small-submenu-module--bodyXsmall--b3296";
export var bodyXxlarge = "header-modal-small-submenu-module--bodyXxlarge--bb329";
export var bodyXxsmall = "header-modal-small-submenu-module--bodyXxsmall--1c6f1";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var headerModalSmallSubmenu = "header-modal-small-submenu-module--headerModalSmallSubmenu--cd8fc";
export var link = "header-modal-small-submenu-module--link--32d19";
export var listItem = "header-modal-small-submenu-module--listItem--ef51f";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var title = "header-modal-small-submenu-module--title--2a3d6";
export var titleLarge = "header-modal-small-submenu-module--titleLarge--12183";
export var titleMedium = "header-modal-small-submenu-module--titleMedium--3e7a3";
export var titleMega = "header-modal-small-submenu-module--titleMega--0a7d9";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "header-modal-small-submenu-module--titleSmall--5788d";
export var titleXlarge = "header-modal-small-submenu-module--titleXlarge--42199";
export var titleXsmall = "header-modal-small-submenu-module--titleXsmall--23813";
export var titleXxlarge = "header-modal-small-submenu-module--titleXxlarge--eca67";
export var titleXxsmall = "header-modal-small-submenu-module--titleXxsmall--f8a4d";