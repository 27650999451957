/* eslint-disable indent */
import PropTypes from 'prop-types';
import React from 'react';
import Link from '../../link/link';
import {
  button,
  allCapsButton,
  fullWidthButton,
  withChevron as withChevronStyle,
} from './button.module.scss';
import classnames from 'classnames';

class Button extends React.Component {
  render() {
    const {
      href,
      type,
      onClick,
      allCaps,
      fullWidth,
      withChevron,
      idLink,
      className,
      children,
      testID = 'button',
    } = this.props;

    const classes = classnames({
      [button]: true,
      [allCapsButton]: allCaps,
      [fullWidthButton]: fullWidth,
      [withChevronStyle]: withChevron,
      [className]: className,
    });

    let ButtonElement;
    if (href) {
      ButtonElement = Link;
    } else {
      ButtonElement = 'button';
    }

    return (
      <ButtonElement
        className={classes}
        type={type}
        to={href}
        onClick={onClick}
        idLink={idLink}
        data-testid={testID}
        id={
          idLink === 'nav-cta-button'
            ? 'nav-cta-button'
            : idLink === 'latest-news-load-more'
            ? 'latest-news-load-more'
            : 'button-click'
        }
      >
        {children}
      </ButtonElement>
    );
  }
}

Button.defaultProps = {
  allCaps: true,
  fullWidth: false,
  type: 'button',
  withChevron: true,
};

Button.propTypes = {
  allCaps: PropTypes.bool,
  fullWidth: PropTypes.bool,
  idLink: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
  withChevron: PropTypes.bool,
  children: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
  testID: PropTypes.string,
};

export default Button;
