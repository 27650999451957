import React, { useState } from 'react';
import * as styles from './search.module.scss';
import classNames from 'classnames';
import SearchIcon from './assets/search.inline.svg';
import PropTypes from 'prop-types';
import { useWindowWidth } from '@react-hook/window-size';
const Search = ({ data: { defaultCategory, categories, extraClasses } }) => {
  const [currentCategory, setCurrentCategory] = useState(defaultCategory);
  const [categoriesExpanded, setCategoriesExpanded] = useState(false);
  const compactMode = window && useWindowWidth() <= 449;

  const getCategory = (name) => {
    return categories.filter((category) => category.name === name)[0];
  };

  const categoryKeyDown = (e) => {
    // Up arrow.
    if (e.keyCode === 38) {
      if (e.target.parentNode.previousSibling) {
        e.target.parentNode.previousSibling.querySelector('button').focus();
      }
      else {
        // Wrap from top option to bottom option. Requires going two levels
        // up the DOM to the <ul>, then getting the last child.
        e.target.parentNode.parentNode.lastElementChild
          .querySelector('button')
          .focus();
      }

      return;
    }
    // Down arrow.
    if (e.keyCode === 40) {
      if (!categoriesExpanded) {
        setCategoriesExpanded(true);
        return;
      }
      if (e.target.parentNode.nextSibling) {
        e.target.parentNode.nextSibling.querySelector('button').focus();
      }
      else {
        // Wrap from bottom option to top option. Requires going two levels
        // up the DOM to the <ul>, then getting the first child.
        e.target.parentNode.parentNode.firstElementChild
          .querySelector('button')
          .focus();
      }

      return;
    }
  };

  const categoryClick = (e) => {
    const clickedCategory = e.target.getAttribute('value');

    setCategoriesExpanded(
      currentCategory === clickedCategory ? !categoriesExpanded : false
    );
    setCurrentCategory(clickedCategory);
  };

  return (
    <form
      className={classNames(styles.search, extraClasses, {
        [styles.compact]: compactMode,
      })}
      data-testid="search"
      method={getCategory(currentCategory)?.method}
      action={getCategory(currentCategory)?.action}
      id="google_search"
    >
      <span className={styles.label} id="search-categories-label">
        Search categories
      </span>
      <ul
        id="search-categories"
        className={classNames(
          styles.categories,
          styles[`categoryCount${categories.length}`],
          {
            [styles.categoriesExpanded]: categoriesExpanded,
          }
        )}
        style={{ '--total-options': categories.length }}
        role="listbox"
        aria-labelledby="search-categories-label"
      >
        {categories &&
          categories.map((category, i) => (
            <li
              key={category.name}
              className={classNames(
                styles.categoryButton,
                styles[`categoryIndex${i}`],
                {
                  [styles.currentCategoryButton]:
                    currentCategory === category.name,
                }
              )}
              role="option"
              aria-selected={currentCategory === category.name}
              aria-label={category.name}
            >
              <button
                type="button"
                onClick={categoryClick}
                onKeyDown={categoryKeyDown}
                value={category.name}
                disabled={
                  !(categoriesExpanded || currentCategory === category.name)
                }
              >
                {category.label}
              </button>
            </li>
          ))}
      </ul>
      <label className={styles.label} htmlFor="search-terms">
        Search Terms
      </label>
      <input
        className={styles.searchTerms}
        type="search"
        name={currentCategory === 'penn-state-news' ? 'q' : 'query'}
        placeholder={compactMode ? 'Search' : 'What are you looking for?'}
      />
      <button className={styles.submit} type="submit">
        <span>Search </span>
        <SearchIcon />
      </button>
      <input
        className="gcse-searchresults-only"
        type="hidden"
        name="category"
        value={currentCategory}
      />
    </form>
  );
};

Search.propTypes = {
  data: PropTypes.shape({
    defaultCategory: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        method: PropTypes.string,
        action: PropTypes.string,
      })
    ).isRequired,
    extraClasses: PropTypes.string,
  }),
};

export default Search;
