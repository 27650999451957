import React from 'react';
import { graphql } from 'gatsby';
import { image } from './image.module.scss';
import PropTypes from 'prop-types';

import FallbackImgSrc from '../../images/fallback.jpg';

const Image = ({ data }) => {
  const { srcs, alt, altText, extraClasses, sizes, idLink } = data || {};

  /**
   * If no srcs there is no image defined.
   * Return fallback image.
   */
  if (!srcs) {
    return <img src={FallbackImgSrc} alt="placeholder image" aria-hidden />;
  }

  const srcset = React.useMemo(() => {
    if (srcs?.responsive) {
      // Construct a srcset value. Given srcs.responsive =
      // [
      //   {
      //     url: image2000.jpg,
      //     size: 2000
      //   },
      //   {
      //     url: image1000.jpg,
      //     size: 1000
      //   }
      // ]
      //
      // the final result will be 'image1000.jpg 1000w, image2000.jpg 2000w'.
      return srcs.responsive
        .sort((a, b) => a.size - b.size)
        .reduce((cumulative, current) => {
          return (
            cumulative +
            (cumulative ? ', ' : '') +
            `${current.url} ${current.size}w`
          );
        }, '');
    }
    return '';
  }, [srcs]);

  // Checking for undefined allows sizes to be set to null,
  // making the prop NOT be used at all.
  return (
    <img
      itemProp="contentUrl"
      content={srcs?.original}
      className={`${extraClasses ? `${extraClasses} ` : ''}${image}`}
      loading="lazy"
      src={srcs?.default}
      srcSet={srcset}
      sizes={typeof sizes === 'undefined' ? 'auto' : sizes}
      alt={alt || altText}
      data-testid="image"
      idLink={idLink}
      id={
        idLink === 'image-gallery-openlightbox' ? idLink : 'article-image-click'
      }
    />
  );
};

Image.dataPropTypes = PropTypes.shape({
  srcs: PropTypes.object,
  altText: PropTypes.string,
  extraClasses: PropTypes.string,
  sizes: PropTypes.string,
  extraAttributes: PropTypes.object,
});

Image.propTypes = {
  data: Image.dataPropTypes,
};

export const SquareLinksFragment = graphql`
  fragment SquareLinksFragment on media__imageField_imageImageDerivativesLinks {
    __typename
    square_1000w {
      href
    }
    square_100w {
      href
    }
    square_1500w {
      href
    }
    square_200w {
      href
    }
    square_250w {
      href
    }
    square_500w {
      href
    }
    square_750w {
      href
    }
  }
`;
export const FourByThreeImageLinksFragment = graphql`
  fragment FourByThreeImageLinksFragment on media__imageField_imageImageDerivativesLinks {
    __typename
    _4_3_2000w {
      href
    }
    _4_3_1500w {
      href
    }
    _4_3_1000w {
      href
    }
    _4_3_500w {
      href
    }
    _4_3_280w {
      href
    }
  }
`;
export const ThreeByFourImageLinksFragment = graphql`
  fragment ThreeByFourImageLinksFragment on media__imageField_imageImageDerivativesLinks {
    __typename
    _3_4_2010w {
      href
    }
    _3_4_1500w {
      href
    }
    _3_4_1020w {
      href
    }
    _3_4_510w {
      href
    }
  }
`;

export const SixteenByNineImageLinksFragment = graphql`
  fragment SixteenByNineImageLinksFragment on media__imageField_imageImageDerivativesLinks {
    __typename
    _16_9_2000w {
      href
    }
    _16_9_1500w {
      href
    }
    _16_9_1000w {
      href
    }
    _16_9_500w {
      href
    }
    _16_9_280w {
      href
    }
  }
`;
export default Image;
