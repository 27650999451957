import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from '../forms/button/button';
import Link from '../link/link';
import {
  outer,
  inner,
  content,
  left,
  mapContainer,
  map,
  subscribeLinksContainer,
  subscribeLinks as subscribeLinksStyle,
  subscribeItem,
  subscribeLink,
  socialName,
  newsLetterTitle,
  newsLetterButton,
  right,
  column,
  linksTitle,
  linksContainer,
  linkContainer,
  link as linkStyle,
  bottom,
  footerLogoContainer,
  footerLogo,
  contact,
  contactLinksContainer,
  noLink,
  subFooterLinks,
  legalLinksContainer,
  copyrightContainer,
  copyright as copyrightStyle,
  weAre,
} from './footer.module.scss';
import FacebookLogo from './assets/fb-white.inline.svg';
import InstagramLogo from './assets/ig-white.inline.svg';
import LinkedInLogo from './assets/in-white.inline.svg';
import TwitterLogo from './assets/twt-white.inline.svg';
import localFooterData from './footerData.json';

const Footer = ({ apiURL }) => {
  const [apiData, setApiData] = useState(false);

  useEffect(() => {
    if (!apiData) {
      fetch(apiURL)
        .then((response) => response.json())
        .then((json) => setApiData(json))
        .catch(() => {
          apiURL &&
            console.warn(
              `Couldn’t fetch footer API at ${apiURL}. Using cached local data.`
            );
          setApiData(localFooterData);
        });
    }
  }, [apiData]);

  if (!apiData) {
    return <footer data-testid="footer" />;
  }

  const allLinks = apiData.map((link) => link);
  // All nav information is on the same nested level,
  // so we grab the first four, which represent the top
  // level nav items we want.
  const navLinks = allLinks.filter((link, index) => index < 4);
  // Currently the only way to differentiate between some links is through actual text.
  const subscribeLinks = allLinks.filter(
    (link) => link.Type === 'Subscribe'
  )?.[0]?.Children?.[0]?.Children;

  // Remove links included in API data but not on main WWW site
  const alteredSubscribeLinks = subscribeLinks.filter((link) => {
    if (link.title !== 'YouTube') {
      return link;
    }
  });

  const social = {
    Facebook: <FacebookLogo />,
    Instagram: <InstagramLogo />,
    Linkedin: <LinkedInLogo />,
    Twitter: <TwitterLogo />,
  };

  // Subfooter items
  const subFooter = allLinks.filter((link) => link.Type === 'Sub-Footer')?.[0]
    ?.Children?.[0]?.Children;
  const address = subFooter.filter((link) => link.title === 'Address')?.[0];
  const telephone = subFooter.filter((link) => link.title === 'Telephone')?.[0];
  const contactUs = subFooter.filter(
    (link) => link.title === 'Contact Us'
  )?.[0];
  const legalLinks = subFooter.filter((link, index) => index > 3 && index < 9);
  const copyright = subFooter.filter(
    (link) => link.title === 'Copyright Information'
  )?.[0];

  // Assets
  const assets = allLinks.filter((link) => link.Type === 'Art')?.[0]
    ?.Children?.[0]?.Children;
  const logo = assets.filter(
    (link) => link.title === 'Penn State Footer Mark'
  )?.[0];
  const mapInfo = assets.filter((link) => link.title === 'Penn State Map')?.[0];
  const weArePenn = subFooter.filter(
    (link) => link.title === 'We Are Penn State'
  )?.[0];

  return (
    <footer className={outer} data-testid="footer">
      <div className={inner}>
        <div className={content}>
          <div className={left}>
            <div className={mapContainer}>
              <img src={mapInfo?.html} alt={mapInfo?.title} className={map} />
            </div>
            <div className={subscribeLinksContainer}>
              <ul className={subscribeLinksStyle} data-testid="subscribe-links">
                {/* Social media icons */}
                {alteredSubscribeLinks.map((link, index) => {
                  // Some items are empty strings, check if valid link.
                  if (link && link?.title !== 'Get News By Email') {
                    return (
                      <li key={`subscribe-${index}`} className={subscribeItem}>
                        <Link
                          to={link.uri}
                          className={subscribeLink}
                          id="footer-social-icon-click"
                        >
                          {social[link?.title]}
                          <span className={socialName}>{link?.title}</span>
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
              {/* Newsletter */}
              {alteredSubscribeLinks.map((subscribeLink, index) => {
                if (subscribeLink?.displayname === 'Get News By Email') {
                  const newsletterTitle = subscribeLink?.title;
                  const newsletterLink = subscribeLink?.uri;
                  const newsletterText = subscribeLink?.html;
                  return (
                    <React.Fragment key={`subscribeLink-${index}`}>
                      <h2 className={newsLetterTitle}>{newsletterTitle}</h2>
                      <Button
                        href={newsletterLink}
                        className={newsLetterButton}
                      >
                        {newsletterText}
                      </Button>
                    </React.Fragment>
                  );
                }
              })}
            </div>
          </div>
          {/* Main footer navigation */}
          <div className={right}>
            {navLinks.map((link, index) => {
              const children = link?.Children?.[0]?.Children;

              return (
                <div key={`column-${index}`} className={column}>
                  <h2 className={linksTitle}>{link?.Type}</h2>
                  <ul className={linksContainer}>
                    {children.map((navItem, index) => {
                      return (
                        <li key={`item-${index}`} className={linkContainer}>
                          <Link
                            to={navItem?.uri}
                            className={linkStyle}
                            id="footer-menu-item-click"
                          >
                            {navItem?.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className={bottom}>
            <div className={footerLogoContainer}>
              {/* TODO: API footer logo currently 404s - manually set to Penn State's home page. */}
              <Link to="https://www.psu.edu/">
                <img
                  src="https://www.psu.edu/components/img/psu-mark-footer.png"
                  alt={logo.title}
                  className={footerLogo}
                  loading="lazy"
                />
              </Link>
            </div>
            {/* Contact Links */}
            <div className={contact}>
              <ul className={contactLinksContainer}>
                <li
                  className={classNames(linkStyle, noLink)}
                  id="footer-menu-item-click"
                >
                  {address?.html}
                </li>
                <li>
                  <Link
                    to={`tel:${telephone?.html}`}
                    className={linkStyle}
                    id="footer-menu-item-click"
                  >
                    {telephone?.html}
                  </Link>
                </li>
                <li>
                  <Link
                    to={contactUs?.uri}
                    className={linkStyle}
                    id="footer-menu-item-click"
                  >
                    {contactUs?.title}
                  </Link>
                </li>
              </ul>
            </div>
            <div className={subFooterLinks}>
              {/* Legal links */}
              <ul className={legalLinksContainer}>
                {legalLinks.map((link, index) => (
                  <li key={`card-${index}`}>
                    <Link
                      to={link?.uri}
                      className={linkStyle}
                      id="footer-menu-item-click"
                    >
                      {link?.displayname}
                    </Link>
                  </li>
                ))}
              </ul>
              {/* Copyright */}
              <ul className={copyrightContainer}>
                <li className={copyrightStyle}>
                  <Link
                    to={copyright?.uri}
                    className={linkStyle}
                    id="footer-menu-item-click"
                  >
                    {copyright?.displayname} &copy; {new Date().getFullYear()}{' '}
                  </Link>
                </li>
                <li>
                  <img
                    src={weArePenn?.html}
                    alt={weArePenn?.title}
                    className={weAre}
                    loading="lazy"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  apiURL: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Footer;
