// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "nav-item-module--ReactModal__Body--open--71847";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var alignRight = "nav-item-module--alignRight--27488";
export var alignRightExceptOnMinimizedLargeDesktop = "nav-item-module--alignRightExceptOnMinimizedLargeDesktop--852d4";
export var bodyLarge = "nav-item-module--bodyLarge--a756e";
export var bodyMedium = "nav-item-module--bodyMedium--7fb06";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "nav-item-module--bodySmall--b4919";
export var bodyXlarge = "nav-item-module--bodyXlarge--40714";
export var bodyXsmall = "nav-item-module--bodyXsmall--61038";
export var bodyXxlarge = "nav-item-module--bodyXxlarge--64c92";
export var bodyXxsmall = "nav-item-module--bodyXxsmall--c6e69";
export var button = "nav-item-module--button--92ce5";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var hasMegaMenu = "nav-item-module--hasMegaMenu--d6314";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var megaMenuItem = "nav-item-module--megaMenuItem--9ba13";
export var megaMenuItemContainer = "nav-item-module--megaMenuItemContainer--11e26";
export var minimized = "nav-item-module--minimized--22679";
export var navItem = "nav-item-module--navItem--29a9d";
export var openMegaMenuItem = "nav-item-module--openMegaMenuItem--3bbbb";
export var openNavItem = "nav-item-module--openNavItem--606eb";
export var titleLarge = "nav-item-module--titleLarge--c230e";
export var titleMedium = "nav-item-module--titleMedium--bd359";
export var titleMega = "nav-item-module--titleMega--f1cb0";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "nav-item-module--titleSmall--557e2";
export var titleXlarge = "nav-item-module--titleXlarge--b1eb7";
export var titleXsmall = "nav-item-module--titleXsmall--fdcf6";
export var titleXxlarge = "nav-item-module--titleXxlarge--072fa";
export var titleXxsmall = "nav-item-module--titleXxsmall--9513a";