import React from 'react';
import Link from '../../link/link';
import PropTypes from 'prop-types';
import HeaderButtonSet from '../header-button-set/header-button-set';
import Search from '../../forms/search/search';
import HeaderModalMainMenu from '../header-modal-main-menu/header-modal-main-menu';
import HeaderModalBigSubmenu from '../header-modal-big-submenu/header-modal-big-submenu';
import HeaderModalSmallSubmenu from '../header-modal-small-submenu/header-modal-small-submenu';
import HeaderModalSocialMenu from '../header-modal-social-menu/header-modal-social-menu';
import classNames from 'classnames';
import { headerModal, titleAndButtons, title, buttons, hotlineLink, search as searchStyle, mainMenu as mainMenuStyle, submenus, bigSubmenu as bigSubmenuStyle, smallSubmenu as smallSubmenuStyle, social as socialStyle } from './header-modal.module.scss';

const HeaderModal = ({ data: { buttonData, search, mainMenu, bigSubmenu, smallSubmenu, social } }) => {

  return (
    <div className={headerModal} data-testid="header-modal">
      <div className={titleAndButtons}>
        <h2 className={title}><Link to="/">Penn State</Link></h2>

        <HeaderButtonSet data={{
          ...buttonData,
          isLightMode: true,
          extraClasses: classNames(buttons, hotlineLink),
        }} />
      </div>

      <Search data={{
        ...search,
        extraClasses: searchStyle
      }} />

      <HeaderModalMainMenu data={{
        ...mainMenu,
        extraClasses: mainMenuStyle
      }} />

      <div className={submenus}>
        <HeaderModalBigSubmenu data={{
          ...bigSubmenu,
          extraClasses: bigSubmenuStyle
        }}
        />

        <HeaderModalSmallSubmenu data={{
          ...smallSubmenu,
          extraClasses: smallSubmenuStyle
        }}/>
      </div>

      <HeaderModalSocialMenu data={{
        ...social,
        extraClasses: socialStyle
      }} />
    </div>
  );
};

HeaderModal.propTypes = {
  data: PropTypes.shape({
    buttonData: HeaderButtonSet.propTypes.data,
    search: Search.propTypes.data,
    mainMenu: HeaderModalMainMenu.propTypes.data,
    bigSubmenu: HeaderModalBigSubmenu.propTypes.data,
    smallSubmenu: HeaderModalSmallSubmenu.propTypes.data,
    social: HeaderModalSocialMenu.propTypes.data,
  }).isRequired,
};

export default HeaderModal;
