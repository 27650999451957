import React from 'react';
import PropTypes from 'prop-types';
import MegaMenuItemCard from '../mega-menu-item-card/mega-menu-item-card';
import MegaMenuItemSubmenu from '../mega-menu-item-submenu/mega-menu-item-submenu';
import { submenu, megaMenuItem } from './mega-menu-item.module.scss';

const MegaMenuItem = React.memo(({ data: { submenuData, cardData, extraClasses } }) => {
  const subProps = React.useMemo(() => {
    return({
      ...submenuData,
      extraClasses: submenu
    });
  }, [submenuData]);
  const cardProps = React.useMemo(() => {
    return({
      ...cardData,
    });
  }, [cardData]);
  return (
    <div className={`${megaMenuItem} ${extraClasses}`} data-testid="mega-menu-item">
      <MegaMenuItemSubmenu data={subProps} />
      <MegaMenuItemCard data={cardProps} />
    </div>
  );
});

MegaMenuItem.displayName = 'MegaMenuItem';

MegaMenuItem.propTypes = {
  data: PropTypes.shape({
    submenuData: MegaMenuItemSubmenu.propTypes.data,
    cardData: MegaMenuItemCard.propTypes.data,
    extraClasses: PropTypes.string,
  })
};

export default MegaMenuItem;

