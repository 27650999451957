// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "banner-alerts-module--ReactModal__Body--open--2918f";
export var alert = "banner-alerts-module--alert--c8420";
export var alertIcon = "banner-alerts-module--alertIcon--97431";
export var alertMessage = "banner-alerts-module--alertMessage--d4d57";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var allClear = "banner-alerts-module--allClear--43280";
export var bodyLarge = "banner-alerts-module--bodyLarge--60fc4";
export var bodyMedium = "banner-alerts-module--bodyMedium--636d1";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "banner-alerts-module--bodySmall--88224";
export var bodyXlarge = "banner-alerts-module--bodyXlarge--c9674";
export var bodyXsmall = "banner-alerts-module--bodyXsmall--0eb81";
export var bodyXxlarge = "banner-alerts-module--bodyXxlarge--2d6e0";
export var bodyXxsmall = "banner-alerts-module--bodyXxsmall--497d8";
export var closeButton = "banner-alerts-module--closeButton--5f010";
export var closeIcon = "banner-alerts-module--closeIcon--7540e";
export var closedAlertIcon = "banner-alerts-module--closedAlertIcon--00e6b";
export var date = "banner-alerts-module--date--2cd09";
export var dateTime = "banner-alerts-module--dateTime--1e054";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var hidden = "banner-alerts-module--hidden--51b64";
export var hiddenText = "banner-alerts-module--hiddenText--fd1c1";
export var immediate = "banner-alerts-module--immediate--a3a99";
export var inner = "banner-alerts-module--inner--4de5a";
export var label = "banner-alerts-module--label--600b2";
export var link = "banner-alerts-module--link--64d6c";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var message = "banner-alerts-module--message--bc44c";
export var nonEmergency = "banner-alerts-module--nonEmergency--bbddc";
export var openButton = "banner-alerts-module--openButton--7cb7c";
export var openIcon = "banner-alerts-module--openIcon--2aacc";
export var outer = "banner-alerts-module--outer--76093";
export var polygon = "banner-alerts-module--polygon--9e684";
export var time = "banner-alerts-module--time--b2855";
export var titleLarge = "banner-alerts-module--titleLarge--96417";
export var titleMedium = "banner-alerts-module--titleMedium--44673";
export var titleMega = "banner-alerts-module--titleMega--594f8";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "banner-alerts-module--titleSmall--c56e2";
export var titleXlarge = "banner-alerts-module--titleXlarge--93014";
export var titleXsmall = "banner-alerts-module--titleXsmall--26aff";
export var titleXxlarge = "banner-alerts-module--titleXxlarge--9c08f";
export var titleXxsmall = "banner-alerts-module--titleXxsmall--dd640";
export var triangle = "banner-alerts-module--triangle--a46b9";
export var urgent = "banner-alerts-module--urgent--5a0d7";