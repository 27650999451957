import React from 'react';
import PropTypes from 'prop-types';
import { nav, navItem } from './nav.module.scss';
import NavItem from '../nav-item/nav-item';

const Nav = ({ data: { items, extraClasses, minimized } }) => {
  return (
    <ul className={`${nav}, ${extraClasses}`} data-testid="nav">
      {items.map((item, index) => (
        <li
          key={item.key}
          className={navItem}
        >
          <NavItem
            data={{...item, minimized: minimized }}
            index={index}
          />
        </li>
      ))}
    </ul>
  );
};

Nav.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(NavItem.propTypes.data),
    minimized: PropTypes.bool.isRequired,
    extraClasses: PropTypes.string,
  }).isRequired,
};

export default Nav;
