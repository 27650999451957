import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../forms/button/button';
import {
  headerButton,
  lightMode,
  hotlineLink,
} from './header-button.module.scss';
import classNames from 'classnames';

const HeaderButton = ({
  children,
  data: { href, isHotlineLink, isLightMode, extraClasses },
}) => {
  return (
    <Button
      className={classNames(headerButton, extraClasses, {
        [lightMode]: isLightMode,
        [hotlineLink]: isHotlineLink,
      })}
      href={href}
      withChevron={false}
      idLink="nav-cta-button"
      testID="header-button"
    >
      {children}
    </Button>
  );
};

HeaderButton.propTypes = {
  data: PropTypes.shape({
    href: PropTypes.string.isRequired,
    isHotlineLink: PropTypes.bool,
    isLightMode: PropTypes.bool,
    extraClasses: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default HeaderButton;
