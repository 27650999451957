// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "image-module--ReactModal__Body--open--8257d";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "image-module--bodyLarge--9f1de";
export var bodyMedium = "image-module--bodyMedium--38467";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "image-module--bodySmall--1bc1e";
export var bodyXlarge = "image-module--bodyXlarge--3ab03";
export var bodyXsmall = "image-module--bodyXsmall--615f1";
export var bodyXxlarge = "image-module--bodyXxlarge--34f7e";
export var bodyXxsmall = "image-module--bodyXxsmall--73fa2";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var image = "image-module--image--9c161";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var titleLarge = "image-module--titleLarge--b8fa2";
export var titleMedium = "image-module--titleMedium--4000f";
export var titleMega = "image-module--titleMega--bb0a5";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "image-module--titleSmall--8d4eb";
export var titleXlarge = "image-module--titleXlarge--041b6";
export var titleXsmall = "image-module--titleXsmall--b2e61";
export var titleXxlarge = "image-module--titleXxlarge--09c76";
export var titleXxsmall = "image-module--titleXxsmall--a0653";