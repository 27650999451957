import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import '../../global/global.scss';
import Header from '../header/header/header';
import Footer from '../footer/footer';
import BannerAlerts from '../banner-alerts/banner-alerts';
import { headerWrap, headerContainer, main } from './layout.module.scss';
import { generateDefaultSchema } from '../../global/generateSchema';
import {QueryParamProvider} from 'use-query-params';
import {ReachAdapter} from 'use-query-params/adapters/reach';

const Layout = ({ children }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  // Push main article down based on height of alert banner/nav
  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  const [bannerAlerts, setBannerAlerts] = useState(null);

  // Banner Alerts - on first visit, grab data from Firebase
  // then set sessionStorage as our data source so subsequent
  // pages don't continue to load/have the banner open.
  useLayoutEffect(() => {
    if (!window.sessionStorage.getItem('alertBanner')) {
      import(/* webpackChunkName: "firebase" */ '../../global/firebase').then((mod) => {
        return mod.default.firestore().collection('alerts').doc('data').get();
      }).then(function(alerts) {
        setBannerAlerts(alerts.data().data);
      }).catch(function(error) {
        console.log('Banner Alerts Error:', error);
      });
    }
    else {
      setBannerAlerts(JSON.parse(window.sessionStorage.getItem('alertBanner')));
    }
  }, []);

  // Handles collapsable banner pushing main content down
  const handleCollapse = () => {
    const main = document.getElementById('main');
    const headerHeight = document.getElementById('header-container').clientHeight;
    main.style.marginTop = `${headerHeight}px`;
  };

  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <QueryParamProvider adapter={ReachAdapter}>
      {!url.includes('/story/') && (
        generateDefaultSchema()
      )}
      <div id="header-container" ref={ref} className={headerWrap} >
        {bannerAlerts &&
          bannerAlerts.map((alert, index) => {
            return <div key={`node-${index}`}>
              <BannerAlerts data={alert} onCollapseBanner={handleCollapse} />
            </div>;
          })
        }
        <div className={headerContainer}>
          <Header bannerHeight={height} apiURL={process.env.GATSBY_HEADER_API_URL} />
        </div>
      </div>
      <main id="main" style={{marginTop: `${height}px`}} className={main}>{children}</main>
      <Footer apiURL={process.env.GATSBY_FOOTER_API_URL} />
    </QueryParamProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
