// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "header-modal-buttons-module--ReactModal__Body--open--79227";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "header-modal-buttons-module--bodyLarge--9884c";
export var bodyMedium = "header-modal-buttons-module--bodyMedium--10d7a";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "header-modal-buttons-module--bodySmall--754fd";
export var bodyXlarge = "header-modal-buttons-module--bodyXlarge--d0bd3";
export var bodyXsmall = "header-modal-buttons-module--bodyXsmall--fb784";
export var bodyXxlarge = "header-modal-buttons-module--bodyXxlarge--40dc7";
export var bodyXxsmall = "header-modal-buttons-module--bodyXxsmall--7985f";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var hamburger = "header-modal-buttons-module--hamburger--cd8db";
export var hamburgerBox = "header-modal-buttons-module--hamburgerBox--4ca26";
export var hamburgerInner = "header-modal-buttons-module--hamburgerInner--4c639";
export var headerModalButtons = "header-modal-buttons-module--headerModalButtons--d59d4";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var search = "header-modal-buttons-module--search--47bcd";
export var titleLarge = "header-modal-buttons-module--titleLarge--fd209";
export var titleMedium = "header-modal-buttons-module--titleMedium--df0db";
export var titleMega = "header-modal-buttons-module--titleMega--4f6d0";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "header-modal-buttons-module--titleSmall--afb7a";
export var titleXlarge = "header-modal-buttons-module--titleXlarge--83770";
export var titleXsmall = "header-modal-buttons-module--titleXsmall--c767c";
export var titleXxlarge = "header-modal-buttons-module--titleXxlarge--a865a";
export var titleXxsmall = "header-modal-buttons-module--titleXxsmall--18211";