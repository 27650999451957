// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "header-modal-module--ReactModal__Body--open--1e1f0";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bigSubmenu = "header-modal-module--bigSubmenu--aec5d";
export var bodyLarge = "header-modal-module--bodyLarge--21dcb";
export var bodyMedium = "header-modal-module--bodyMedium--14340";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "header-modal-module--bodySmall--b91a6";
export var bodyXlarge = "header-modal-module--bodyXlarge--7b4a3";
export var bodyXsmall = "header-modal-module--bodyXsmall--74444";
export var bodyXxlarge = "header-modal-module--bodyXxlarge--7d5d5";
export var bodyXxsmall = "header-modal-module--bodyXxsmall--eb4ee";
export var buttons = "header-modal-module--buttons--78291";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var headerModal = "header-modal-module--headerModal--a4e52";
export var hotlineLink = "header-modal-module--hotlineLink--011bb";
export var mainMenu = "header-modal-module--mainMenu--01960";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var search = "header-modal-module--search--e74be";
export var smallSubmenu = "header-modal-module--smallSubmenu--7f8c3";
export var social = "header-modal-module--social--e3e38";
export var submenus = "header-modal-module--submenus--0aa9d";
export var title = "header-modal-module--title--f3bfa";
export var titleAndButtons = "header-modal-module--titleAndButtons--5b1eb";
export var titleLarge = "header-modal-module--titleLarge--1c804";
export var titleMedium = "header-modal-module--titleMedium--fcb5c";
export var titleMega = "header-modal-module--titleMega--d9e32";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "header-modal-module--titleSmall--24ec2";
export var titleXlarge = "header-modal-module--titleXlarge--097fb";
export var titleXsmall = "header-modal-module--titleXsmall--3cba5";
export var titleXxlarge = "header-modal-module--titleXxlarge--055ab";
export var titleXxsmall = "header-modal-module--titleXxsmall--50f81";