// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "header-button-set-module--ReactModal__Body--open--e3add";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "header-button-set-module--bodyLarge--1ce17";
export var bodyMedium = "header-button-set-module--bodyMedium--98e29";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "header-button-set-module--bodySmall--9794f";
export var bodyXlarge = "header-button-set-module--bodyXlarge--a531c";
export var bodyXsmall = "header-button-set-module--bodyXsmall--442cc";
export var bodyXxlarge = "header-button-set-module--bodyXxlarge--3ab1e";
export var bodyXxsmall = "header-button-set-module--bodyXxsmall--ef3f5";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var headerButtonSet = "header-button-set-module--headerButtonSet--354a0";
export var hideOnMobile = "header-button-set-module--hideOnMobile--3e14d";
export var listItem = "header-button-set-module--listItem--638ba";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var titleLarge = "header-button-set-module--titleLarge--cd38a";
export var titleMedium = "header-button-set-module--titleMedium--b120a";
export var titleMega = "header-button-set-module--titleMega--e107a";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "header-button-set-module--titleSmall--0f8d0";
export var titleXlarge = "header-button-set-module--titleXlarge--f03c1";
export var titleXsmall = "header-button-set-module--titleXsmall--594cb";
export var titleXxlarge = "header-button-set-module--titleXxlarge--d9a0f";
export var titleXxsmall = "header-button-set-module--titleXxsmall--e4dbf";