// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "button-module--ReactModal__Body--open--d1765";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var allCapsButton = "button-module--allCapsButton--66e13";
export var bodyLarge = "button-module--bodyLarge--bad6e";
export var bodyMedium = "button-module--bodyMedium--e526a";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "button-module--bodySmall--70f8c";
export var bodyXlarge = "button-module--bodyXlarge--f5eba";
export var bodyXsmall = "button-module--bodyXsmall--c29e7";
export var bodyXxlarge = "button-module--bodyXxlarge--ef925";
export var bodyXxsmall = "button-module--bodyXxsmall--4f4c8";
export var button = "button-module--button--4353c";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var fullWidthButton = "button-module--fullWidthButton--a15f5";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var titleLarge = "button-module--titleLarge--2126e";
export var titleMedium = "button-module--titleMedium--a01d9";
export var titleMega = "button-module--titleMega--363e1";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "button-module--titleSmall--99f16";
export var titleXlarge = "button-module--titleXlarge--92dc1";
export var titleXsmall = "button-module--titleXsmall--3d250";
export var titleXxlarge = "button-module--titleXxlarge--7044e";
export var titleXxsmall = "button-module--titleXxsmall--9d1a6";
export var withChevron = "button-module--withChevron--4aa77";