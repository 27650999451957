import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../link/link';
import { headerModalSocialMenu, title, list, link as linkStyle } from './header-modal-social-menu.module.scss';
import FacebookIcon from './assets/facebook.inline.svg';
import InstagramIcon from './assets/instagram.inline.svg';
import TwitterIcon from './assets/twitter.inline.svg';
import LinkedInIcon from './assets/linkedin.inline.svg';
import YoutubeIcon from './assets/youtube.inline.svg';

const HeaderModalSocialMenu = ({ data: { links, extraClasses } }) => {
  const networks = {
    facebook: {
      verb: 'Like',
      icon: <FacebookIcon />,
    },
    twitter: {
      verb: 'Follow',
      icon: <TwitterIcon />,
    },
    instagram: {
      verb: 'Follow',
      icon: <InstagramIcon />,
    },
    linkedin: {
      verb: 'Follow',
      icon: <LinkedInIcon />,
    },
    youtube: {
      verb: 'Follow',
      icon: <YoutubeIcon />,
    }
  };

  return (
    <div className={`${headerModalSocialMenu} ${extraClasses}`} data-testid="header-modal-social-menu">
      <h3 className={title}>Connect With Us <span>{'//'}</span></h3>
      <ul className={list}>
        { links.map(link => (
          <li key={link.network}>
            <Link className={linkStyle} to={link.url} title={`${networks[link.network.toLowerCase()]?.verb} us on ${link.network}`}>
              {networks[link.network.toLowerCase()]?.icon}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

HeaderModalSocialMenu.propTypes = {
  data: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.shape({
      network: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })),
    extraClasses: PropTypes.string
  }).isRequired,
};

export default HeaderModalSocialMenu;
