import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../link/link';
import {
  megaMenuItemSubmenu,
  item,
  link,
} from './mega-menu-item-submenu.module.scss';

const MegaMenuItemSubmenu = React.memo(({ data: { items, extraClasses } }) => {
  return (
    <ul
      className={`${megaMenuItemSubmenu} ${extraClasses}`}
      data-testid="mega-menu-item-submenu"
    >
      {items.map(({ key, url, title }) => (
        <li key={key} className={item}>
          <Link className={link} id="nav-main-menu-click" to={url}>
            {title}
          </Link>
        </li>
      ))}
    </ul>
  );
});
MegaMenuItemSubmenu.displayName = 'MegaMenuItemSubmenu';

MegaMenuItemSubmenu.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.array.isRequired,
    extraClasses: PropTypes.string,
  }),
};

export default MegaMenuItemSubmenu;
