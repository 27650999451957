import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import dayjs from 'dayjs';
import OpenIcon from './assets/icon--caret.inline.svg';
import AlertIcon from './assets/icon--alert.inline.svg';
import CloseIcon from './assets/icon--x.inline.svg';
import Link from '../link/link';
import {
  outer,
  immediate,
  urgent,
  allClear,
  nonEmergency,
  openButton,
  hidden,
  closedAlertIcon,
  hiddenText,
  openIcon,
  inner,
  dateTime,
  date as dateStyle,
  time,
  alert,
  alertIcon,
  alertMessage as alertMessageStyle,
  message,
  link,
  triangle,
  polygon,
  closeButton,
  closeIcon,
} from './banner-alerts.module.scss';

const BannerAlerts = ({
  data,
  data: {
    field_alert_type: alertType,
    field_short_title: openText,
    field_publish_on: date,
    field_text: alertMessage,
  },
  onCollapseBanner,
}) => {
  const defaultOpenMsg = () => {
    if (alertType && (alertType === 'urgent') | (alertType === 'immediate')) {
      return true;
    } else {
      return false;
    }
  };

  const [messageOpen, setOpen] = useState(defaultOpenMsg());
  const url = data?.field_link?.uri ?? '';
  let linkText = data?.field_link?.title ?? '';

  const toggleMessage = () => {
    setOpen(!messageOpen);
  };

  useEffect(() => {
    onCollapseBanner();
  });

  // Custom formatting date here as this component receives data
  // directly from an API endpoint without graphql transformation.
  let readableDate;
  let readableTime;

  if (date) {
    readableDate = dayjs(date).format('MMMM DD, YYYY');
    readableTime = dayjs(date).format('h:mm A');
  }

  // Link text can get passed as an empty string.
  if (linkText?.length === 0) {
    linkText = 'More Info >';
  }

  const outerClass = classnames(outer, {
    [immediate]: alertType === 'immediate',
    [urgent]: alertType === 'urgent',
    [allClear]: alertType === 'all-clear',
    [nonEmergency]: alertType === 'non-emergency',
  });

  return (
    <div className={outerClass}>
      <button
        className={classnames(openButton, messageOpen ? hidden : '')}
        onClick={toggleMessage}
      >
        <AlertIcon className={closedAlertIcon} />
        {openText}
        <span className={hiddenText}>&nbsp;Open Alert</span>
        <OpenIcon className={openIcon} />
      </button>
      <div className={classnames(inner, !messageOpen ? hidden : '')}>
        <div className={dateTime}>
          {date && (
            <>
              <p className={dateStyle}>{readableDate}</p>
              <p className={time}>{readableTime}</p>
            </>
          )}
        </div>
        <div className={alert}>
          <AlertIcon className={alertIcon} />
          <div className={alertMessageStyle}>
            <span
              className={message}
              data-testid="banner-text"
              dangerouslySetInnerHTML={{ __html: alertMessage }}
            />
            {url && (
              <Link to={url} className={link} data-testid="banner-link">
                {linkText}
              </Link>
            )}
          </div>
          <div className={triangle}></div>
          <div className={polygon}></div>
        </div>
        <button className={closeButton} onClick={toggleMessage}>
          <CloseIcon className={closeIcon} />
          Close<span className={hiddenText}>&nbsp;Alert</span>
        </button>
      </div>
    </div>
  );
};

BannerAlerts.propTypes = {
  data: PropTypes.shape({
    field_alert_type: PropTypes.string.isRequired,
    field_link: PropTypes.shape({
      uri: PropTypes.string,
      title: PropTypes.string,
    }),
    field_publish_on: PropTypes.string,
    field_short_title: PropTypes.string,
    field_text: PropTypes.string.isRequired,
  }).isRequired,
  onCollapseBanner: PropTypes.func,
};

export default BannerAlerts;
