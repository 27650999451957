// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "layout-module--ReactModal__Body--open--0dd17";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "layout-module--bodyLarge--6196e";
export var bodyMedium = "layout-module--bodyMedium--8ada8";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "layout-module--bodySmall--2ab1b";
export var bodyXlarge = "layout-module--bodyXlarge--01cc0";
export var bodyXsmall = "layout-module--bodyXsmall--e9c26";
export var bodyXxlarge = "layout-module--bodyXxlarge--3084d";
export var bodyXxsmall = "layout-module--bodyXxsmall--a25b8";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var headerContainer = "layout-module--headerContainer--fdc84";
export var headerWrap = "layout-module--headerWrap--b7f72";
export var main = "layout-module--main--3f0fd";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var titleLarge = "layout-module--titleLarge--df819";
export var titleMedium = "layout-module--titleMedium--f8603";
export var titleMega = "layout-module--titleMega--49b6a";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "layout-module--titleSmall--84217";
export var titleXlarge = "layout-module--titleXlarge--65f2b";
export var titleXsmall = "layout-module--titleXsmall--42447";
export var titleXxlarge = "layout-module--titleXxlarge--c58eb";
export var titleXxsmall = "layout-module--titleXxsmall--34f93";