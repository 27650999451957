import React from 'react';
import PropTypes from 'prop-types';
import HeaderButton from '../header-button/header-button';
import classNames from 'classnames';
import {
  headerButtonSet,
  listItem,
  hideOnMobile,
} from './header-button-set.module.scss';

const HeaderButtonSet = ({
  data: { buttons, isLightMode, onlyHotlineOnMobile, extraClasses },
}) => {
  return (
    <ul
      className={classNames(headerButtonSet, extraClasses)}
      data-testid="header-button-set"
    >
      {buttons.map((button) => (
        <li key={button.key} className={listItem}>
          <HeaderButton
            id="nav-cta-button"
            data={{
              href: button.url,
              isHotlineLink: button.isHotlineLink,
              isLightMode,
              extraClasses:
                onlyHotlineOnMobile && !button.isHotlineLink
                  ? hideOnMobile
                  : '',
            }}
          >
            {button.title}
          </HeaderButton>
        </li>
      ))}
    </ul>
  );
};

HeaderButtonSet.propTypes = {
  data: PropTypes.shape({
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        isHotlineLink: PropTypes.bool,
      })
    ),
    isLightMode: PropTypes.bool,
    onlyHotlineOnMobile: PropTypes.bool,
    extraClasses: PropTypes.string,
  }),
};

export default HeaderButtonSet;
