// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "mega-menu-item-card-module--ReactModal__Body--open--e1017";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "mega-menu-item-card-module--bodyLarge--fd6ce";
export var bodyMedium = "mega-menu-item-card-module--bodyMedium--c0bdc";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "mega-menu-item-card-module--bodySmall--208e3";
export var bodyXlarge = "mega-menu-item-card-module--bodyXlarge--fdedc";
export var bodyXsmall = "mega-menu-item-card-module--bodyXsmall--9fd63";
export var bodyXxlarge = "mega-menu-item-card-module--bodyXxlarge--10fc7";
export var bodyXxsmall = "mega-menu-item-card-module--bodyXxsmall--ce930";
export var date = "mega-menu-item-card-module--date--0d8e5";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var image = "mega-menu-item-card-module--image--089db";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var megaMenuItemCard = "mega-menu-item-card-module--megaMenuItemCard--081fc";
export var text = "mega-menu-item-card-module--text--d9cf5";
export var title = "mega-menu-item-card-module--title--27976";
export var titleLarge = "mega-menu-item-card-module--titleLarge--7e202";
export var titleMedium = "mega-menu-item-card-module--titleMedium--490e5";
export var titleMega = "mega-menu-item-card-module--titleMega--dfdfe";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "mega-menu-item-card-module--titleSmall--6e6be";
export var titleXlarge = "mega-menu-item-card-module--titleXlarge--91d08";
export var titleXsmall = "mega-menu-item-card-module--titleXsmall--0583c";
export var titleXxlarge = "mega-menu-item-card-module--titleXxlarge--6c338";
export var titleXxsmall = "mega-menu-item-card-module--titleXxsmall--2bb77";