// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "header-button-module--ReactModal__Body--open--66761";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "header-button-module--bodyLarge--66556";
export var bodyMedium = "header-button-module--bodyMedium--22eb8";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "header-button-module--bodySmall--fdfe7";
export var bodyXlarge = "header-button-module--bodyXlarge--b4643";
export var bodyXsmall = "header-button-module--bodyXsmall--682f6";
export var bodyXxlarge = "header-button-module--bodyXxlarge--4d92f";
export var bodyXxsmall = "header-button-module--bodyXxsmall--acfdd";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var headerButton = "header-button-module--headerButton--2bff7";
export var hotlineLink = "header-button-module--hotlineLink--25f1e";
export var lightMode = "header-button-module--lightMode--e195c";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var titleLarge = "header-button-module--titleLarge--9babe";
export var titleMedium = "header-button-module--titleMedium--515da";
export var titleMega = "header-button-module--titleMega--d6655";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "header-button-module--titleSmall--f24b4";
export var titleXlarge = "header-button-module--titleXlarge--fb3d5";
export var titleXsmall = "header-button-module--titleXsmall--01281";
export var titleXxlarge = "header-button-module--titleXxlarge--73c31";
export var titleXxsmall = "header-button-module--titleXxsmall--9a980";