import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MegaMenuItem from '../mega-menu-item/mega-menu-item';
import classNames from 'classnames';
import {
  megaMenuItem,
  openMegaMenuItem,
  navItem,
  hasMegaMenu,
  alignRight,
  alignRightExceptOnMinimizedLargeDesktop,
  minimized as minimizedStyle,
  openNavItem,
  button,
  megaMenuItemContainer,
} from './nav-item.module.scss';
import useDebounce from '../../../global/hooks/useDebounce';

const NavItem = React.memo(
  ({
    index,
    data: { key, url, title, megaMenuItemData, extraClasses, minimized },
  }) => {
    const TitleElement = url ? 'a' : 'button';

    const [itemIsOpen, setItemIsOpen] = useState(false);
    // TODO: Delay set to zero to match WWW. Adjust as teams
    // align on improvements.
    const debouncedItemIsOpen = useDebounce(itemIsOpen, 0);

    const hoverRef = React.useRef();

    useEffect(() => {
      const title = hoverRef.current;
      if (title) {
        const mouseenter = () => {
          if (!itemIsOpen) {
            setItemIsOpen(true);
          }
        };
        const mouseleave = () => {
          if (itemIsOpen) {
            setItemIsOpen(false);
          }
        };
        title.addEventListener('mouseenter', mouseenter);
        title.addEventListener('mouseleave', mouseleave);
        return () => {
          title.removeEventListener('mouseenter', mouseenter);
          title.removeEventListener('mouseleave', mouseleave);
        };
      }
    }, [hoverRef, setItemIsOpen, itemIsOpen]);

    const megaMenuItemMemoizedData = React.useMemo(() => {
      return {
        ...megaMenuItemData,
        extraClasses: classNames(megaMenuItem, {
          [openMegaMenuItem]: debouncedItemIsOpen,
        }),
      };
    }, [megaMenuItemData, debouncedItemIsOpen]);

    const divClassNames = React.useMemo(() => {
      return classNames(navItem, extraClasses, {
        [hasMegaMenu]: megaMenuItemData,
        [alignRight]: index > 2,
        [alignRightExceptOnMinimizedLargeDesktop]: index === 2,
        [minimizedStyle]: minimized,
        [openNavItem]: debouncedItemIsOpen,
      });
    }, [extraClasses, index, megaMenuItemData, debouncedItemIsOpen, minimized]);
    return (
      <div
        className={divClassNames}
        key={key}
        data-testid="nav-item"
        ref={hoverRef}
      >
        <TitleElement className={button} id="nav-main-menu-click" href={url}>
          {title}
        </TitleElement>
        {megaMenuItemData && (
          <div className={megaMenuItemContainer}>
            <MegaMenuItem data={megaMenuItemMemoizedData} />
          </div>
        )}
      </div>
    );
  }
);

NavItem.displayName = 'NavItem';

NavItem.propTypes = {
  index: PropTypes.number,
  data: PropTypes.shape({
    key: PropTypes.string.isRequired,
    url: PropTypes.string,
    title: PropTypes.string.isRequired,
    megaMenuItemData: MegaMenuItem.propTypes.data,
    alignRight: PropTypes.bool,
    extraClasses: PropTypes.string,
    minimized: PropTypes.bool,
  }),
};

export default NavItem;
