import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderButtonSet from '../header-button-set/header-button-set';
import HeaderModalButtons from '../header-modal-buttons/header-modal-buttons';
import HeaderModal from '../header-modal/header-modal';
import Nav from '../nav/nav';
import Modal from 'react-modal';
import useScrollPosition from '../../../global/hooks/useScrollPosition';
import classNames from 'classnames';
import restructureData from './restructure-data';
import {
  header,
  minimized as minimizedStyle,
  headerContent,
  container,
  title,
  right,
  topRow,
  buttons,
  modalButtons,
  nav as navStyle,
  modal,
  slidIntoViewModal,
  overlay,
  closeButton,
  closeButtonText,
} from './header.module.scss';
import localHeaderData from './headerData.json';

const Header = ({ bannerHeight = 0, apiURL }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSlidIntoView, setModalSlidIntoView] = useState(modalOpen);
  const [minimized, setMinimized] = useState(false);
  const [apiData, setApiData] = useState(false);
  const [restructuredData, setRestructuredData] = useState(null);

  useEffect(() => {
    if (!apiData) {
      fetch(apiURL, { mode: 'cors' })
        .then((response) => response.json())
        .then((json) => setApiData(json))
        .catch(() => {
          apiURL &&
            console.warn(
              `Couldn’t fetch header API at ${apiURL}. Using cached local data.`
            );
          setApiData(localHeaderData);
        });
    }
  }, [apiData]);

  useEffect(() => {
    if (apiData && !restructuredData) {
      setRestructuredData(restructureData(apiData));
    }
  }, [apiData, restructuredData]);

  const slideModalIn = () => {
    setModalSlidIntoView(true);
  };

  const slideModalOut = () => {
    setModalSlidIntoView(false);
  };

  const toggleModal = () => {
    setModalOpen((prevModalOpen) => !prevModalOpen);
  };

  const modalRequestClose = () => {
    slideModalOut();
    toggleModal();
  };

  useScrollPosition(({ currPos }) => {
    // bannerHeight accounts for alert banner.
    if (currPos.y === bannerHeight && minimized) {
      setMinimized(false);
    } else if (!minimized) {
      setMinimized(true);
    }
  });

  if (!restructuredData) {
    return <header data-testid="header" />;
  }

  const { nav, buttonData, headerModal } = restructuredData;

  return (
    <header
      className={classNames(
        header,
        {
          [minimizedStyle]: minimized,
        },
        {
          headerRegular: !minimized,
        }
      )}
      data-testid="header"
    >
      <div className={headerContent}>
        <div className={container}>
          <h1 className={title}>
            <a id="nav-main-logo" href="https://www.psu.edu/">
              Penn State
            </a>
          </h1>
          <div className={right}>
            <div className={topRow}>
              <HeaderButtonSet
                data={Object.assign({}, buttonData, {
                  extraClasses: buttons,
                  onlyHotlineOnMobile: true,
                })}
              />
              <HeaderModalButtons
                data={{
                  extraClasses: modalButtons,
                  clickFunction: toggleModal,
                }}
              />
            </div>
            <Nav
              data={Object.assign({}, nav, {
                extraClasses: navStyle,
                minimized: minimized,
              })}
            />
          </div>
        </div>
        <Modal
          isOpen={modalOpen}
          onRequestClose={modalRequestClose}
          onAfterOpen={slideModalIn}
          closeTimeoutMS={410}
          className={classNames(modal, {
            [slidIntoViewModal]: modalSlidIntoView,
          })}
          overlayClassName={overlay}
        >
          <HeaderModal data={headerModal} />
          <button onClick={modalRequestClose} className={closeButton}>
            <span className={closeButtonText}>
              Close <span>Navigation</span>
            </span>
          </button>
        </Modal>
      </div>
    </header>
  );
};

Header.propTypes = {
  bannerHeight: PropTypes.number,
  apiURL: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Header;
