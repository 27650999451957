import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  headerModalMainMenuItem,
  expanded as expandedStyle,
  titleAndButton,
  title as titleStyle,
  expandButton,
  children as childrenStyle,
} from './header-modal-main-menu-item.module.scss';
import MegaMenuItemSubmenu from '../mega-menu-item-submenu/mega-menu-item-submenu';
import classNames from 'classnames';

const HeaderModalMainMenuItem = ({
  data: { key, url, title, children, extraClasses },
}) => {
  const TitleElement = url ? 'a' : 'button';
  const [expanded, setExpanded] = useState(false);

  const toggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <li
      className={classNames(headerModalMainMenuItem, extraClasses, {
        [expandedStyle]: expanded,
      })}
      key={key}
      data-testid="header-modal-main-menu-item"
    >
      <div className={titleAndButton}>
        <TitleElement
          className={titleStyle}
          id="nav-main-menu-click"
          href={url}
          onClick={children && TitleElement === 'button' ? toggle : null}
        >
          {title}
        </TitleElement>
        {children && (
          <button
            className={expandButton}
            onClick={toggle}
            aria-label={expanded ? 'Collapse' : 'Expand'}
          >
            {expanded ? '–' : '+'}
          </button>
        )}
      </div>
      {children && (
        <MegaMenuItemSubmenu
          data={{
            extraClasses: childrenStyle,
            items: children,
          }}
        />
      )}
    </li>
  );
};

HeaderModalMainMenuItem.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string.isRequired,
    url: PropTypes.string,
    title: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    extraClasses: PropTypes.string,
  }),
};

export default HeaderModalMainMenuItem;
