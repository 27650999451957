// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "header-modal-main-menu-item-module--ReactModal__Body--open--69c09";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "header-modal-main-menu-item-module--bodyLarge--07829";
export var bodyMedium = "header-modal-main-menu-item-module--bodyMedium--81467";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "header-modal-main-menu-item-module--bodySmall--5932c";
export var bodyXlarge = "header-modal-main-menu-item-module--bodyXlarge--ac3a5";
export var bodyXsmall = "header-modal-main-menu-item-module--bodyXsmall--bbad5";
export var bodyXxlarge = "header-modal-main-menu-item-module--bodyXxlarge--5f499";
export var bodyXxsmall = "header-modal-main-menu-item-module--bodyXxsmall--ea180";
export var children = "header-modal-main-menu-item-module--children--51839";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var expandButton = "header-modal-main-menu-item-module--expandButton--f920e";
export var expanded = "header-modal-main-menu-item-module--expanded--55069";
export var headerModalMainMenuItem = "header-modal-main-menu-item-module--headerModalMainMenuItem--3ec2c";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var title = "header-modal-main-menu-item-module--title--905b8";
export var titleAndButton = "header-modal-main-menu-item-module--titleAndButton--bb186";
export var titleLarge = "header-modal-main-menu-item-module--titleLarge--ba260";
export var titleMedium = "header-modal-main-menu-item-module--titleMedium--f49a0";
export var titleMega = "header-modal-main-menu-item-module--titleMega--b76c0";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "header-modal-main-menu-item-module--titleSmall--84957";
export var titleXlarge = "header-modal-main-menu-item-module--titleXlarge--d1f07";
export var titleXsmall = "header-modal-main-menu-item-module--titleXsmall--a1d57";
export var titleXxlarge = "header-modal-main-menu-item-module--titleXxlarge--bb5c4";
export var titleXxsmall = "header-modal-main-menu-item-module--titleXxsmall--f80c0";