import React from 'react';
import PropTypes from 'prop-types';
import { headerModalMainMenu } from './header-modal-main-menu.module.scss';
import HeaderModalMainMenuItem from '../header-modal-main-menu-item/header-modal-main-menu-item';

const HeaderModalMainMenu = ({ data: { items, extraClasses = '' } }) => {
  return (
    <ul className={`${headerModalMainMenu} ${extraClasses}`} data-testid="header-modal-main-menu">
      {items.map(item => (
        <HeaderModalMainMenuItem data={item} key={item.key} />
      ))}
    </ul>
  );
};

HeaderModalMainMenu.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.array.isRequired,
    extraClasses: PropTypes.string,
  })
};

export default HeaderModalMainMenu;

