// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "header-modal-big-submenu-module--ReactModal__Body--open--7b08f";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "header-modal-big-submenu-module--bodyLarge--14407";
export var bodyMedium = "header-modal-big-submenu-module--bodyMedium--eef51";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "header-modal-big-submenu-module--bodySmall--08a91";
export var bodyXlarge = "header-modal-big-submenu-module--bodyXlarge--08ec4";
export var bodyXsmall = "header-modal-big-submenu-module--bodyXsmall--86d7e";
export var bodyXxlarge = "header-modal-big-submenu-module--bodyXxlarge--23769";
export var bodyXxsmall = "header-modal-big-submenu-module--bodyXxsmall--9c3be";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var headerModalBigSubmenu = "header-modal-big-submenu-module--headerModalBigSubmenu--43d6f";
export var link = "header-modal-big-submenu-module--link--29f1f";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var title = "header-modal-big-submenu-module--title--d9eae";
export var titleLarge = "header-modal-big-submenu-module--titleLarge--27be9";
export var titleMedium = "header-modal-big-submenu-module--titleMedium--687b0";
export var titleMega = "header-modal-big-submenu-module--titleMega--e7cf7";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "header-modal-big-submenu-module--titleSmall--6748c";
export var titleXlarge = "header-modal-big-submenu-module--titleXlarge--4e070";
export var titleXsmall = "header-modal-big-submenu-module--titleXsmall--6345b";
export var titleXxlarge = "header-modal-big-submenu-module--titleXxlarge--a964e";
export var titleXxsmall = "header-modal-big-submenu-module--titleXxsmall--e9be4";