/*
 * Skeleton of the object we're going to be populating from the API data.
 */
const data = {
  nav: {
    items: [
      {
        key: '',
        url: '',
        title: '',
        megaMenuItemData: {
          cardData: {
            imageData: {
              srcs: {
                default: '',
                original: '',
                responsive: [
                  {
                    url: '',
                    size: 0,
                  },
                ],
              },
            },
            link: '',
            title: '',
            date: '',
          },
          submenuData: {
            items: [
              {
                key: '',
                url: '',
                title: '',
              },
            ],
          },
        },
      },
    ],
  },
  buttonData: {
    buttons: [
      {
        id: '',
        title: '',
        url: '',
        isHotlineLink: false,
      },
    ],
  },
  headerModal: {
    buttonData: {
      buttons: [
        {
          id: '',
          title: '',
          url: '',
          isHotlineLink: false,
        },
      ],
    },
    search: {
      defaultCategory: '',
      categories: [
        {
          name: '',
          label: '',
          method: '',
          action: '',
        },
      ],
    },
    mainMenu: {
      items: [
        {
          key: '',
          url: '',
          title: '',
          children: [
            {
              key: '',
              url: '',
              title: '',
            },
          ],
        },
      ],
    },
    bigSubmenu: {
      title: '',
      links: [
        {
          key: '',
          url: '',
          title: '',
        },
      ],
    },
    smallSubmenu: {
      title: '',
      links: [
        {
          key: '',
          url: '',
          title: '',
        },
      ],
    },
    social: {
      links: [
        {
          network: '',
          url: '',
        },
      ],
    },
  },
};

/**
 * Filter an API item's Children by whether a given property matches a given value
 * or set of values.
 *
 * @param {Object} apiData Any item from the standard.psu.edu header API response.
 * @param {string|string[]} propertyValues Value (or list of values) to match for the given propertyName.
 * @param {string} propertyName Property name whose value to test.
 * @param {bool} negate If true, will return all children whose property does NOT match the given propertyValues.
 * @returns {Object[]} The matching children.
 */
const getChildrenByProperty = (
  apiData,
  propertyValues,
  propertyName,
  negate = false
) => {
  if (!Array.isArray(propertyValues)) {
    propertyValues = [propertyValues];
  }

  const length = apiData.Children.length;
  const matchingChildren = [];
  const nonMatchingChildren = [];

  for (let i = 0; i < length; i++) {
    let currentChild = apiData.Children[i];

    // The API is returning an empty string for some array items. Skip them.
    if (!currentChild) {
      continue;
    }

    if (propertyValues.indexOf(currentChild[propertyName]) !== -1) {
      matchingChildren.push(currentChild);
    }
    else {
      nonMatchingChildren.push(currentChild);
    }
  }

  if (negate) {
    return nonMatchingChildren;
  }

  return matchingChildren;
};

/**
 * Filter an API item's children by whether they have one of the given slugs.
 *
 * @param {Object} apiData Any item from the standard.psu.edu API response.
 * @param {string|string[]} slugs Slug (or set of slugs) to match.
 * @param {bool} negate If true, will return all children whose slugs do NOT match the provided slugs.
 * @returns {Object[]} The matching children.
 */
const getChildrenBySlug = (apiData, slugs, negate) => {
  return getChildrenByProperty(apiData, slugs, 'slug', negate);
};

/**
 * Turn the search API item into the structure expected by the Search component,
 * and add it to the data object.
 * @param {Object} apiData The API item for the search categories.
 * @returns {void}
 */
const restructureSearch = (apiData) => {
  // Hard-coded default category, since the API doesn't present them
  // in the correct order.
  const defaultCategory = 'penn-state-news';

  // We'll reuse the searchData variable to hold most of our transformations
  // of the data. First, get the top level search entry.
  var searchData = getChildrenBySlug(apiData, 'search');

  // Next, get all the children except the one representing the logo,
  // which I believe shouldn't be here.
  searchData = getChildrenBySlug(searchData[0], 'penn-state-mark', true);

  // Separate out the default category, make its path relative, and remove
  // unnecessary search options.
  const searchOptions = [];
  searchData.forEach((item) => {
    if (item.slug === defaultCategory) {
      item.uri = '/search/news/';
      searchOptions.unshift(item);
    }
    else if (
      item.url_title !== 'departments' &&
      item.url_title !== 'people'
    ) {
      searchOptions.push(item);
    }
  });

  // Construct our actual component data for the different categories.
  const categories = searchOptions.map((item) => {
    return {
      name: item.slug,
      label: item.title,
      method: 'get',
      action: item.uri,
    };
  });

  // Add our final component data for the search to the main object.
  data.headerModal.search = {
    defaultCategory: defaultCategory,
    categories: categories,
  };
};

/**
 * Restructure a single navigation link into the format required by
 * the NavItem component.
 *
 * @param {Object} apiData The API item for a single nav link.
 * @param {int} navItemIndex Index of current nav item. Used to determine whether to show mega menu to left or right.
 * @returns {Object} The restructured nav item.
 */
const restructureNavLinkForMainNav = (apiData, navItemIndex) => {
  const navItem = {
    key: apiData.slug,
    url: apiData.uri,
    title: apiData.displayname,
  };

  if (!apiData?.Children?.length) {
    return navItem;
  }

  // The easiest way to distinguish the card entry is to see if its Decorate
  // property is set to 0 or 1.
  const submenuChildren = getChildrenByProperty(apiData, 0, 'Decorate');
  const cardData = getChildrenByProperty(apiData, 1, 'Decorate').filter(
    (item) => item.DecorateSRC !== 'test decorate src'
  )?.[0];
  const length = submenuChildren.length;

  if (submenuChildren.length > 0 || cardData) {
    navItem.megaMenuItemData = {};
    navItem.alignRight = navItemIndex > 1;
  }

  if (submenuChildren.length > 0) {
    let submenuItems = [];

    for (let i = 0; i < length; i++) {
      let child = submenuChildren[i];
      submenuItems.push({
        key: child.slug,
        url: child.uri,
        title: child.displayname,
      });
    }

    navItem.megaMenuItemData.submenuData = {
      items: submenuItems,
    };
  }

  if (cardData) {
    navItem.megaMenuItemData.cardData = {
      imageData: {
        srcs: {
          default: cardData.card_src,
        },
        altText: cardData.card_alt,
      },
      link: cardData.card_anchor,
      title: cardData.card_heading,
    };
  }

  return navItem;
};

/**
 * Restructure a single navigation link into the format required by
 * the HeaderModal component.
 *
 * @param {Object} apiData The API item for a single nav link.
 * @returns {Object} The restructured nav item.
 */
const restructureNavLinkForModal = (apiData) => {
  const navItem = {
    key: apiData.slug,
    url: apiData.uri,
    title: apiData.title,
  };

  if (!apiData?.Children?.length) {
    return navItem;
  }

  // The easiest way to distinguish the card entry is to see if its Decorate
  // property is set to 0 or 1.
  const submenuChildren = getChildrenByProperty(apiData, 0, 'Decorate');
  const length = submenuChildren.length;

  if (submenuChildren.length > 0) {
    let submenuItems = [];

    for (let i = 0; i < length; i++) {
      let child = submenuChildren[i];
      submenuItems.push({
        key: child.slug,
        url: child.uri,
        title: child.displayname,
      });
    }

    navItem.children = submenuItems;
  }

  return navItem;
};

/**
 * Restructure the API data for the navigation into the format required by the Nav
 * component, then add it to the `data` object.
 * @param {Object} apiData The API item for most of the navigation items.
 * @returns {void}
 */
const restructureNav = (apiData) => {
  const length = apiData.Children.length;
  const restructuredNavLinks = [];
  const restructuredModalNavLinks = [];
  for (let i = 0; i < length; i++) {
    restructuredNavLinks.push(
      restructureNavLinkForMainNav(apiData.Children[i], i, false)
    );
    restructuredModalNavLinks.push(
      restructureNavLinkForModal(apiData.Children[i])
    );
  }

  data.nav.items = restructuredNavLinks;
  data.headerModal.mainMenu.items = restructuredModalNavLinks;
};

const restructureNewsNav = (apiData) => {
  data.nav.items.push(
    restructureNavLinkForMainNav(apiData.Children[0], data.nav.items.length)
  );
  data.headerModal.mainMenu.items.push(
    restructureNavLinkForModal(apiData.Children[0])
  );
};

const restructureButtons = (apiData) => {
  const length = apiData.Children.length;
  const buttons = [];
  for (let i = 0; i < length; i++) {
    let child = apiData.Children[i];
    buttons.push({
      key: child.slug,
      title: child.title,
      url: child.uri,
      isHotlineLink: child.slug === 'hotline',
    });
  }

  data.buttonData.buttons = buttons;
  data.headerModal.buttonData.buttons = buttons;
};

const restructureSocialLinks = (apiData) => {
  const socialLinkData = apiData.Children[0].Children;
  const length = socialLinkData.length;
  const socialLinks = [];
  for (let i = 0; i < length; i++) {
    let socialLink = socialLinkData[i];

    if (!socialLink) {
      continue;
    }

    socialLinks.push({
      network: socialLink.slug,
      url: socialLink.uri,
    });
  }

  data.headerModal.social.links = socialLinks;
};

const restructureModalSubmenu = (apiData) => {
  const submenu = {
    title: apiData.Children[0].title,
    links: [],
  };
  const linkData = apiData.Children[0].Children;
  const length = linkData.length;

  for (let i = 0; i < length; i++) {
    let link = linkData[i];

    if (!link) {
      continue;
    }

    submenu.links.push({
      key: link.slug,
      url: link.uri,
      title: link.title,
    });
  }

  return submenu;
};

const restructureModalBigSubmenu = (apiData) => {
  data.headerModal.bigSubmenu = restructureModalSubmenu(apiData);
};

const restructureModalSmallSubmenu = (apiData) => {
  data.headerModal.smallSubmenu = restructureModalSubmenu(apiData);
};

const functionFromDataType = {
  'Search and Mark Components': restructureSearch,
  'Master Navigation': restructureNav,
  'News Navigation': restructureNewsNav,
  'Calls to Action': restructureButtons,
  'Social Media Navigation': restructureSocialLinks,
  'Information For Navigation': restructureModalBigSubmenu,
  'Helpful Links Navigation': restructureModalSmallSubmenu,
};

const divvyTopLevelItems = (apiData) => {
  const length = apiData.length;
  for (let i = 0; i < length; i++) {
    let currentItem = apiData[i];
    if (currentItem.Type in functionFromDataType) {
      functionFromDataType[currentItem.Type](currentItem);
    }
  }
};

export default (apiData) => {
  divvyTopLevelItems(apiData);
  return data;
};
