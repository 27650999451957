// extracted by mini-css-extract-plugin
export var ReactModal__BodyOpen = "mega-menu-item-submenu-module--ReactModal__Body--open--eed79";
export var alertpaletteBrightkeystone = "#ffd100";
export var alertpaletteCreeklight = "#cfeceb";
export var alertpaletteFuturescalling = "#9c0";
export var alertpaletteFuturescallingdark = "#4a7729";
export var alertpaletteOriginal87 = "#bc204b";
export var alertpaletteOriginal87dark = "#98022b";
export var bodyLarge = "mega-menu-item-submenu-module--bodyLarge--6cce3";
export var bodyMedium = "mega-menu-item-submenu-module--bodyMedium--b834d";
export var bodySizesBodyLarge = "bodyLarge";
export var bodySizesBodyMedium = "bodyMedium";
export var bodySizesBodySmall = "bodySmall";
export var bodySizesBodyXLarge = "bodyXlarge";
export var bodySizesBodyXSmall = "bodyXsmall";
export var bodySizesBodyXxLarge = "bodyXxlarge";
export var bodySizesBodyXxSmall = "bodyXxsmall";
export var bodySmall = "mega-menu-item-submenu-module--bodySmall--237b1";
export var bodyXlarge = "mega-menu-item-submenu-module--bodyXlarge--6fb31";
export var bodyXsmall = "mega-menu-item-submenu-module--bodyXsmall--50cb6";
export var bodyXxlarge = "mega-menu-item-submenu-module--bodyXxlarge--ceb11";
export var bodyXxsmall = "mega-menu-item-submenu-module--bodyXxsmall--c3aef";
export var elementpaletteBrightkeystone = "#ffd100";
export var elementpaletteButtonhover = "linear-gradient(to bottom, #009cde, #1e407c)";
export var elementpalettePalink = "#005fa9";
export var elementpalettePalinklight = "#ccf0ff";
export var item = "mega-menu-item-submenu-module--item--15e49";
export var link = "mega-menu-item-submenu-module--link--29e7c";
export var mainpaletteBeaverblue = "#1e407c";
export var mainpaletteCreek = "#3ea39e";
export var mainpaletteCreeklight = "#cfeceb";
export var mainpaletteCreekmaxlight = "#edf8f7";
export var mainpaletteEndlesspotential = "#000321";
export var mainpaletteLimestone = "#a2aaad";
export var mainpaletteLimestonelight = "#e4e5e7";
export var mainpaletteLimestonemaxlight = "#f2f2f4";
export var mainpaletteLionshrine = "#b88965";
export var mainpaletteLionshrinelight = "#f7f2ee";
export var mainpaletteLionshrinemaxlight = "#fdfbf5";
export var mainpaletteLionsroar = "#bf8226";
export var mainpaletteLionsroarlight = "#f9eddc";
export var mainpaletteLionsroarmaxlight = "#fffaf2";
export var mainpaletteMidnightblue = "#002e69";
export var mainpaletteNittanynavy = "#001e44";
export var mainpaletteOldcoaly = "#444";
export var mainpalettePasky = "#009cde";
export var mainpaletteSlate = "#314d64";
export var mainpaletteSlatelight = "#ccdae6";
export var mainpaletteSlatemaxlight = "#eef3f7";
export var mainpaletteWhiteout = "#fff";
export var megaMenuItemSubmenu = "mega-menu-item-submenu-module--megaMenuItemSubmenu--8dedc";
export var titleLarge = "mega-menu-item-submenu-module--titleLarge--3b751";
export var titleMedium = "mega-menu-item-submenu-module--titleMedium--7fb4f";
export var titleMega = "mega-menu-item-submenu-module--titleMega--0f587";
export var titleSizesTitleLarge = "titleLarge";
export var titleSizesTitleMedium = "titleMedium";
export var titleSizesTitleMega = "titleMega";
export var titleSizesTitleSmall = "titleSmall";
export var titleSizesTitleXLarge = "titleXlarge";
export var titleSizesTitleXSmall = "titleXsmall";
export var titleSizesTitleXxLarge = "titleXxlarge";
export var titleSizesTitleXxSmall = "titleXxsmall";
export var titleSmall = "mega-menu-item-submenu-module--titleSmall--a71c3";
export var titleXlarge = "mega-menu-item-submenu-module--titleXlarge--402df";
export var titleXsmall = "mega-menu-item-submenu-module--titleXsmall--59264";
export var titleXxlarge = "mega-menu-item-submenu-module--titleXxlarge--5e8a1";
export var titleXxsmall = "mega-menu-item-submenu-module--titleXxsmall--6eac8";