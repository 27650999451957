import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../link/link';
import {
  image,
  megaMenuItemCard,
  text,
  title as titleStyle,
  date as dateStyle,
} from './mega-menu-item-card.module.scss';
import Image from '../../image/image';

const MegaMenuItemCard = React.memo(
  ({ data: { imageData, link, title, date, extraClasses } }) => {
    let updatedImageData;

    if (imageData) {
      updatedImageData = { ...imageData, sizes: '280px', extraClasses: image };
    }

    return (
      <article
        className={`${megaMenuItemCard} ${extraClasses}`}
        data-testid="mega-menu-item-card"
      >
        <Link to={link} id="nav-feature-story-click">
          {updatedImageData && <Image data={updatedImageData} />}

          <div className={text}>
            <h2 className={titleStyle}>{title}</h2>
            {date && <time className={dateStyle}>{date}</time>}
          </div>
        </Link>
      </article>
    );
  }
);
MegaMenuItemCard.displayName = 'MegaMenuItemCard';

MegaMenuItemCard.propTypes = {
  data: PropTypes.shape({
    imageData: Image.propTypes.data,
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    extraClasses: PropTypes.string,
  }),
};

export default MegaMenuItemCard;
